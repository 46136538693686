import React, {useState} from 'react';
import Popup from 'reactjs-popup';
import { ThemeProvider } from '@mui/material/styles';
import OrderArrow from './OrderArrow';
import './ShowItems.css';

const ShowItems = (props) => {
  const [open, setOpen] = useState(false);  
  const closeModal = () => setOpen(false);

  const clickHandler = (event) => {
    setOpen(o => !o);
    props.getOneItem(event.target.parentElement.dataset.id); //gets data-id from <tr>
  }

  const contentStyle = { 
    width: '70vw', 
    padding:'20px', 
    marginTop:"5vh",
    overflowY:"auto",
  }
   
    return (
    <div>
      <ThemeProvider theme={props.theme} >
      <Popup open={open} closeOnDocumentClick onClose={closeModal}
      {...{ contentStyle}}
      >
      <div>
        <h2 style={{marginTop:"-2vh"}}>{props.description.title}</h2>
        <p>Posted {props.description.ddate}</p>
        <div dangerouslySetInnerHTML={ {__html: props.description.description } } />
      </div>
      </Popup>

    <table><thead><tr><th style={{width:"22%"}}><OrderArrow order={props.order} orderHandler={props.orderHandler} /></th><th style={{width:"50%"}} >Position</th><th>Category</th></tr>
    </thead>

    <tbody onClick={clickHandler} >

    {props.items.map((item) => (
    <tr key={item.id} data-id={item.id}><td>{item.ddate}</td><td>{item.title}</td>  <td>{item.category}</td></tr>
    )
    )
    }
  </tbody></table>

  </ThemeProvider>
  </div>
  )
}

export default ShowItems;