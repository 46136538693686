import './ShowButtons.css';
import Button from '@mui/material/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ThemeProvider } from '@mui/material/styles';

const ShowButtons = (props) => {

const changeCategory = (cat) => {
  props.categoryHandler(cat);
}

const changeInfo = () => {
  props.flipInfo();
}

const contentStyle = { width:'70vw', padding:'20px', marginTop:"5vh" };

return (
<div>
<ThemeProvider theme={props.theme} >
<Button variant={props.category==='' ? "contained": "outlined"} size="small"
onClick={() => changeCategory('')}>
Everything</Button>
&nbsp;

<Button variant={props.category==='job' ? "contained": "outlined"} size="small"
onClick={() => changeCategory('job')}>
Jobs</Button>
&nbsp;

<Button variant={props.category==='internship' ? "contained": "outlined"} size="small"
onClick={() => changeCategory('internship')}>
Internships</Button>
&nbsp;
<Button variant={props.category==='short' ? "contained": "outlined"} size="small"
onClick={() => changeCategory('short')}>
Short-Term</Button>
&nbsp;

  <Popup 
    trigger={<Button variant="outlined" size="small">Readme</Button>}
  {...{ contentStyle }}
  modal
  >
<div className="content">
        <h2>
About ETBD.IO Jobs + Internships</h2>

<div dangerouslySetInnerHTML={ {__html: props.info } } />

</div>
  </Popup>

  </ThemeProvider>
  </div>
    )

}

export default ShowButtons;