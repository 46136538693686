import React, { useState, useEffect } from 'react';
import ShowItems from './components/ShowItems';
//import ShowDescription from './components/ShowDescription';
import ShowButtons from './components/ShowButtons';
import { createTheme } from '@mui/material/styles';


const App = () => {
  const [id,setId] = useState(null);
  const [description,setDescription] = useState({id:null,title:"",description:""});
  const [items,setItems] = useState([]);
  const [order,setOrder] = useState('DESC');
  const [category,setCategory] = useState('');
  const [info,setInfo] = useState('');

  //e6ffff 5eb8ff b3e5fc
  const theme = createTheme({
    palette: {
      secondary: {
        light: '#e6ffff',
        main: '#93c3dc',
        dark: '#82b3c9',
        contrastText: '#000',
      },
      primary: {
        light: '5eb8ff',
        main: '#0288D1',
        dark: '#005b9f',
        contrastText: '#fff',
      },
    },
  });
  



  const orderHandler = () => {
    if (order === 'DESC') {
      setOrder('ASC');
    }
    else {
      setOrder('DESC');
    }
  }
  
  const categoryHandler = (cat) => {
    setCategory(cat);
  }

  async function getInfo() {
    const response = await fetch('./get_message.php');
    const data = await response.text();
    setInfo(data);
  }

  async function getItems() {
    const response = await fetch(`./get_items.php?category=${category}&order=${order}`);
    const data = await response.json();
    setItems([...data]);
  }

  async function getOneItem(id) {
    const response = await fetch(`./get_description.php?id=${id}`);
    const data = await response.json();
    setDescription({...data});
  }

  const clearDescription = () => {
    setDescription(null);
  }
  
  useEffect(() => {
    getItems();
  },[order, category]);
   
useEffect(() => {
  getInfo();
},[]);

   return (

<div>
  <main>
  <h1>ETBD.io Jobs + Internships</h1>

<ShowButtons category={category} theme={theme} categoryHandler={categoryHandler} info={info} />

<ShowItems items={items} theme={theme} getOneItem={getOneItem} order={order} orderHandler={orderHandler} description={description} />



</main>
</div>

)



}

export default App;